.fs-12{
    font-size: 12px !important;
}

.fs-14{
    font-size: 14px !important;
}
.fs-16{
    font-size: 16px !important;
}
.fs-18{
    font-size: 18px !important;
}

.fs-20{
    font-size: 20px;
}
.fs-22{
    font-size: 22px;
}
.fs-24{
    font-size: 24px;
}
.fw-500{
    font-weight: 500;
}

.fw-600{
    font-weight: 600;
}

.fw-700{
    font-weight: 700;
}
.fw-800{
    font-weight: 800;
}

.text-brown{
    color: #9F7B4B;
}
.color-black{
    color: #212B36;
}
@media (min-width:365px) and (max-width:575px)  {
    .mobile-font-22{
        font-size: 20px;
    }
}