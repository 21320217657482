.floor-sec-divider{
    border: 1px solid rgba(4, 4, 5, 0.1);
}
.floor-plan{
    height: 100%;
    align-items: center;
    border: 1px solid #133462;
    /* border-radius: 5px; */
    width: auto;
    padding: 15px;
}

.floor-img{
    height: 500px;
    background-position: center;
    background-size: contain;
    /* background-size: cover; */
    background-repeat: no-repeat;
}
.floor-img:hover{
    cursor: pointer;
    -ms-zoom-animation:inherit;
}

@media (min-width:365px) and (max-width:575px)  {
    .floor-img{
        height: 300px;
    }
}