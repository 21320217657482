.carousal-image{
    height: 450px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    
}
.custom-carousal-style{
    .react-multi-carousel-track{
        gap: 10px;
    }
    .react-multiple-carousel__arrow{
        border-radius:0px ;
        background: #fff;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);

    }
    .react-multiple-carousel__arrow--left{
        left: calc(5% + 1px);
    }
    .react-multiple-carousel__arrow--right{
        right: calc(5% + 1px);
    }
    .react-multiple-carousel__arrow:hover{
        background: #fff;
        color: #fff;
        }
    .react-multiple-carousel__arrow::before{
        color: #222;
    }
}

@media (min-width:365px) and (max-width:565px){
    .react-multiple-carousel__arrow--left{
        left: calc(5% + 1px) !important;
        top: 50%;
    }
    .react-multiple-carousel__arrow--right{
        right: calc(5% + 1px) !important;
        top: 50%;
    }
    .carousal-image{
        height: 251px;
        margin-top: 62px;
    }
    .react-multi-carousel-track{
        gap: 0px !important;
    }

}

