.sec-divider{
    border: 1px solid rgba(4, 4, 5, 0.1);
    padding: 30px 30px;
    margin: 10px auto;
}
.virtual-img{
    height: 580px;
}
@media (min-width:365px) and (max-width:575px)  {
    .virtual-img{
        height: 300px;
    }
    .sec-divider{
        padding: 20px;
    }
}