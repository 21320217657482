.hearder-bg{
    background: #FFF !important;
    box-shadow: 0px 4px 15px 0px rgba(34, 34, 34, 0.05);    
    padding-bottom: 0;
}
.empty-div {
    min-height: 36px;
}

.nav-item a.active{
    color: #9F7B4B !important;
    font-weight: 600;
    border-bottom: 3px solid #9F7B4B !important;
}

.cool-link {
    display: inline-block;
    text-decoration: none;
}
.mobile-nav-link{
    width: 100%;
    text-align: center;
}
.nav-li-width{
    width: 25%;
    text-decoration: none;
}
.cool-link::after {
    content: '';
    display: block;
    width: 0;
    height: 3px;
    background: #9F7B4B;
    transition: width .5s;
}

/* .cool-link:hover::after {
    width: 100%;
    color: #9F7B4B;
    transition: width .5s;
}
.cool-link:hover:active {
    color: #9F7B4B;
    width: 100%;
    transition: width .5s;
} */

.nav-item a.active{
    color: #9F7B4B !important;
    font-weight: 600;
    border-bottom: 3px solid #9F7B4B !important;
}