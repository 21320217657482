*{
    /* font-family: 'Josefin Sans' !important; */
    /* font-family: 'Karla' !important; */
    font-family: 'Plus Jakarta Sans', sans-serif;
}

.container{
    width: 1080px !important;
    max-width: 1920px !important;
}

@media (min-width:365px) and (max-width:765px) {
    .container{
        width: 100% !important;
    }
}

.destination-scroll{
    position:absolute;
	z-index:-1;
	left:0;
	margin-top:-69px;
}
.view-btn{
    border: 1px solid #9F7B4B;
    padding: 5px 20px;
    background: transparent;
    color: #9F7B4B;
    font-weight: 600;
    text-decoration: none;
}
.view-btn:hover{
    color: #fff;
    background: #9F7B4B;
    transition: .6s;
}