
.sec-divider{
    border: 1px solid rgba(4, 4, 5, 0.1);
    padding: 30px 30px;
    margin: 10px auto;
}
.add-width{
    width: 180px;
}

.info-sec{
    border: 1px solid #04040520;
    background: #FFF;
    padding: 30px 15px ;
}